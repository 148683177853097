import React from "react"
import { FaFacebook, FaInstagram } from "react-icons/fa"
export default [
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/barroncarpetsandfloors",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/barroncarpetsandfloors/",
  },
]
